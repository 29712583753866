import { $, $$ } from 'utils/dom';
import { Player } from '@3pc/player-videojs';
import { Accordion } from '@3pc/accordion';

// init player (mp4)
const players = $$('.video-player--mp4');

const playerConfig = {};

players.length &&
  players.forEach(playerEl => {
    new Player({
      player: playerEl,
      config: playerConfig,
    }).init();
  });

// init transcript accordions
const accordionItems = $$('.video-player__content');

const initAccordion = container => {
  const handler = $('.video-player__content-trigger', container);
  const target = $('.video-player__content-panel', container);
  const targetInner = $('.video-player__content-panel-inner', target);

  if (!handler || !target || !targetInner) return;

  const accordion = new Accordion({
    target,
    targetInner,
    handler,
    easing: 'easeInOutQuart',
    duration: 400,
    initOpened: false,
    openClassName: 'is-open',
  });
  accordion.init();
};

// loop over each accordion item and initialize it
accordionItems.forEach(accordionItem => {
  return initAccordion(accordionItem);
});
