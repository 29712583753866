/** @jsx h */
import { labels } from '../config';
import { useRef, useEffect } from 'preact/hooks';

const Pagination = ({ pagination, triggerLoadMore, pluginNamespace }) => {
  const paginationInputEl = useRef(null);

  useEffect(() => {
    if (paginationInputEl && paginationInputEl.current) {
      paginationInputEl.current.value = '';
    }
  }, []);

  function handleLoadMoreClick(event) {
    event && event.preventDefault();
    if (paginationInputEl && paginationInputEl.current) {
      paginationInputEl.current.value = pagination.current + 1;
      triggerLoadMore();
    }
  }

  /* Only show load more button, if there is something to load */
  if (
    pagination &&
    (pagination.current === pagination.numberOfPages ||
      pagination.numberOfPages === 0)
  ) {
    return '';
  }

  return (
    <nav class="pager-container">
      <ul class="pager">
        <li>
          <input
            type="text"
            name={`${pluginNamespace}[page]`}
            hidden
            readonly
            ref={paginationInputEl}
          />
          <button
            role="button"
            onClick={handleLoadMoreClick}
            aria-description="Inhalte der nächsten Seite laden"
          >
            {labels.pagination.loadMore}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
