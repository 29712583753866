/** @jsx h */
import { $$ } from 'utils/dom';
import { labels, showSort } from '../config';
import { useRef, useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import SortingFieldset from './SortingFieldset';

const SearchHeader = ({
  query,
  pluginNamespace,
  triggerAjaxSubmit,
  sortings,
  resultsPerPage,
}) => {
  const [searchQuery, setSearchQuery] = useState(query);
  const searchInputEl = useRef(null);

  useEffect(() => setSearchQuery(query), [query]);

  function handleReset() {
    const inputTexts = $$('input[type="text"]');
    const inputCheckboxes = $$('input[type="checkbox"]');
    const inputSelects = $$('input[type="select"]');

    // reset all inputs
    inputTexts.length && inputTexts.forEach(input => (input.value = ''));

    inputCheckboxes.length &&
      inputCheckboxes.forEach(input => (input.checked = false));

    inputSelects.length && inputSelects.forEach(input => (input.value = ''));

    triggerAjaxSubmit();
  }

  function handleInputChange() {
    if (searchInputEl && searchInputEl.current) {
      const value = searchInputEl.current.value;
      setSearchQuery(value);
    }
  }

  return (
    <fieldset role="form" class="search-form">
      <legend class="sr-only">{labels.search.searchField}</legend>
      <div class="form-group">
        <label for="search-term" class="sr-only">
          {labels.search.searchField}
        </label>
        <input
          id="search-term"
          className="form-control input-lg"
          data-main-input
          name={`${pluginNamespace}[q]`}
          value={searchQuery}
          ref={searchInputEl}
          onChange={handleInputChange}
          autocomplete="off"
          placeholder={labels.search.placeholder || ''}
          type="text"
        />
      </div>

      {showSort && (
        <Fragment>
          <SortingFieldset
            pluginNamespace={pluginNamespace}
            triggerAjaxSubmit={triggerAjaxSubmit}
            sortings={sortings}
            resultsPerPage={resultsPerPage}
          />
          <div class="form-group-submit">
            <button type="reset" class="btn btn-default" onClick={handleReset}>
              {labels.faceting.resetQuery}
            </button>

            <button type="submit" class="btn btn-primary">
              {labels.search.submit}
            </button>
          </div>
        </Fragment>
      )}
    </fieldset>
  );
};

export default SearchHeader;
