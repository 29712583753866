/** @jsx h */
const Checkbox = ({ option, filterName, handleChange, pluginNamespace }) => {
  return (
    <li class={option.selected ? 'active' : ''}>
      <label
        className="search-section__checkbox"
        for={`${filterName}-${option.value}`}
      >
        <input
          id={`${filterName}-${option.value}`}
          className="sr-only"
          type="checkbox"
          name={`${pluginNamespace}[filter][]`}
          value={`${filterName}:${option.value}`}
          data-label={option.label}
          onChange={handleChange}
          checked={option.selected}
        />
        <span>{option.label}</span>
      </label>
    </li>
  );
};

export default Checkbox;
