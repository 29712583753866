/** @jsx h */
import { useState } from 'preact/hooks';
import { labels, showFacets, searchType } from '../config';
import FilterContainer from './FilterContainer';
import ResultContainer from './ResultContainer';

const GridContainer = ({
  inputQuery,
  searchData,
  pluginNamespace,
  handleSubmit,
  handleLoadMore,
  resultIsEmpty,
  isError,
  resultList,
}) => {
  const [listView, setListView] = useState(false);

  function toggleListView() {
    setListView(!listView);
  }

  const seachClass =
    searchType === 'person'
      ? 'is-person-grid'
      : searchType === 'study'
      ? 'overview-section'
      : searchType === 'calendar'
      ? 'is-event-grid'
      : '';

  return (
    <section
      className={`grid ${seachClass} has-controls ${
        listView ? 'show-as-list' : ''
      }`}
    >
      {showFacets && (
        <FilterContainer
          query={inputQuery}
          facets={searchData.facets}
          triggerAjaxSubmit={handleSubmit}
          pluginNamespace={pluginNamespace}
          listView={listView}
          toggleListView={toggleListView}
        />
      )}

      {resultIsEmpty ? (
        <div className="search-section__empty">
          {labels.results.nothingFound.replace('@searchWord', inputQuery)}
        </div>
      ) : isError ? (
        <div className="search-section__error">{labels.search.failed}</div>
      ) : (
        <ResultContainer
          searchType={searchType}
          allResultCount={searchData.allResultCount}
          list={resultList}
          pagination={searchData.documents.pagination}
          loadMore={handleLoadMore}
          pluginNamespace={pluginNamespace}
        />
      )}
    </section>
  );
};

export default GridContainer;
