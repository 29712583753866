/** @jsx h */
import { Fragment } from 'preact';
import Pagination from './Pagination';

const TeaserEvent = ({ list, pagination, loadMore, pluginNamespace }) => {
  function handleLoadMoreClick() {
    loadMore();
  }

  return (
    <Fragment>
      <div class="row">
        {list.map((item, index) => (
          <article
            class={`grid-item ${item.state}`}
            key={`teaser-event-${index}`}
          >
            <a href={item.url}>
              {item.image ? (
                <div class="article-image">
                  <img
                    src={item.image.src}
                    alt={item.image.alt}
                    width="600"
                    height="600"
                  />
                </div>
              ) : (
                ''
              )}

              <header>
                {item.subSubTitle && <h4>{item.subSubTitle}</h4>}
                <h3>{item.mark}</h3>
                <h2>{item.title}</h2>
                <div class="meta-info">{item.subtitle}</div>
                {item.state !== 'default' ? (
                  <div className="badge-alert">
                    <div
                      className="badge-alert-inner"
                      dangerouslySetInnerHTML={{ __html: item.stateText }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </header>
            </a>
          </article>
        ))}
      </div>

      <Pagination
        pagination={pagination}
        triggerLoadMore={handleLoadMoreClick}
        pluginNamespace={pluginNamespace}
      />
    </Fragment>
  );
};

export default TeaserEvent;
