/** @jsx h */
import { Fragment } from 'preact';
import { labels, showCount } from '../config';
import Pagination from './Pagination';
import ResultTitle from './ResultTitle';
import Checkbox from './Checkbox';

const TeaserGeneral = ({
  allResultCount,
  list,
  pagination,
  loadMore,
  pluginNamespace,
  triggerAjaxSubmit,
  facets,
}) => {
  function handleLoadMoreClick() {
    loadMore();
  }

  function handleCheckboxChange() {
    triggerAjaxSubmit();
  }

  return (
    <div className="row">
      <div class="col-asides col-sticky">
        <div class="col-asides-affix affix-top">
          <h2 class="sr-only">{labels.search.filterAdvanced}</h2>
          <aside class="search-facet">
            {facets.map(facet => (
              <Fragment key={facet.name}>
                <h3 id="search-facet-areas">{facet.label}</h3>
                <ul aria-labelledby="search-facet-areas">
                  {facet.options.map(option => (
                    <Checkbox
                      key={option.value}
                      option={option}
                      filterName={facet.name}
                      handleChange={handleCheckboxChange}
                      pluginNamespace={pluginNamespace}
                    />
                  ))}
                </ul>
              </Fragment>
            ))}
          </aside>
        </div>
      </div>

      <div class="col-content search-items">
        {allResultCount && showCount && (
          <div className="search-section__result-header">
            <ResultTitle allResultCount={allResultCount} />
          </div>
        )}
        <div class="grid is-search-grid show-as-list">
          <div class="row">
            {list.map((item, index) => (
              <article class="grid-item" key={`teaser-general-${index}`}>
                <a href={item.url}>
                  <div class="inner-grid-item-content">
                    <header>
                      {item.title && <h2>{item.title}</h2>}
                      {item.subtitle && (
                        <div class="meta-info">{item.subtitle}</div>
                      )}
                    </header>
                    <p>{item.teaser}</p>
                  </div>
                </a>
              </article>
            ))}
          </div>

          <Pagination
            pagination={pagination}
            triggerLoadMore={handleLoadMoreClick}
            pluginNamespace={pluginNamespace}
          />
        </div>
      </div>
    </div>
  );
};

export default TeaserGeneral;
