/** @jsx h */
import { labels } from '../config';
import { Fragment } from 'preact';

const ResultTitle = ({ allResultCount }) => {
  return (
    <h2 className="result-count" aria-live="polite">
      {allResultCount === 1 ? (
        labels.results.foundSingular
      ) : (
        <Fragment>
          {labels.results.found &&
            labels.results.found.replace('@resultsTotal', allResultCount)}
        </Fragment>
      )}
    </h2>
  );
};

export default ResultTitle;
