import { $, insertHTML, remove } from 'utils/dom';

/*
// Usage:
import AjaxContainer from 'modules/ajax-container';

new AjaxContainer({
  // targets is an array of node elements. Each element must has "data-ajaxuri" attribute (the ajax enpoint).
  // The Attribute "data-ajaxconfig" is optional. if not set, the first ajax call will always replace the whole ajaxContainer
  targets: ajaxContainers,
  onLoad: () => {
    // your stuff when ajax starts loading (e.g. start some loading animation).
  },
  onFinished: () => {
    // your stuff after ajaxCall is done (e.g. end loading animation, lazyload new loaded images or init some javascript).
  },
}).init();
*/

export default class AjaxContainer {
  constructor(props) {
    this.targets = props.targets;
    this.onLoad = props.onLoad;
    this.onFinished = props.onFinished;
    this.count = 0;
    this.axios = null;
  }

  ajaxLoad = (target, ajaxUrl, ajaxConfig) => {
    this.onLoad && this.onLoad(target, ajaxUrl, ajaxConfig);
  };

  ajaxFinished = (target, ajaxUrl, ajaxConfig) => {
    this.onFinished && this.onFinished(target, ajaxUrl, ajaxConfig);
  };

  startNextAjaxCall = () => {
    // count up, after each finished ajaxCalls and start the ajaxCall for the next element in array
    this.count++;
    if (this.count < this.targets.length) {
      this.ajaxCall(
        this.targets[this.count],
        this.targets[this.count].dataset.ajaxuri,
        this.targets[this.count].dataset.ajaxconfig
      );
    }
  };

  ajaxCall = (target, ajaxUrl, ajaxConfig) => {
    this.ajaxLoad(target, ajaxUrl, ajaxConfig);

    this.axios
      .get(ajaxUrl)
      .then(({ data }) => {
        // Parse response string to html.
        const $response = new DOMParser().parseFromString(data, 'text/html')
          .body.firstChild;

        // if there is no config we gonna replace the whole container
        if (ajaxConfig) {
          this.handleConfigResponse($response, target, ajaxUrl, ajaxConfig);
        } else {
          this.replaceHTML($response, target, ajaxUrl, ajaxConfig);
        }

        this.startNextAjaxCall(); // start next ajaxCall in array
      })
      .catch(error => {
        this.ajaxFinished(target, ajaxUrl, ajaxConfig);
        this.startNextAjaxCall(); // start next ajaxCall in array
        console.error(error);
      });
  };

  replaceHTML = ($response, target, ajaxUrl, ajaxConfig) => {
    // replace innterHTML
    target.innerHTML = $response.innerHTML;

    this.checkForLoadMore(target);
    this.ajaxFinished(target, ajaxUrl, ajaxConfig);
  };

  handleConfigResponse = ($response, target, ajaxUrl, ajaxConfig) => {
    const configArray = JSON.parse(ajaxConfig);

    configArray.forEach(config => {
      const responseWrapper = $(config.container, $response);
      const domWrapper = $(config.container, target);

      if (responseWrapper && config.append) {
        // We want to append the children, not the container itself.
        const childrens = responseWrapper.children;

        childrens.length &&
          childrens.forEach(children => {
            insertHTML(domWrapper, 'beforeend', children.outerHTML);
          });
      } else {
        if (responseWrapper) {
          domWrapper.outerHTML = responseWrapper.outerHTML;
        } else {
          remove($(config.container, target));
        }
      }
    });

    this.checkForLoadMore();
    this.ajaxFinished(target, ajaxUrl, ajaxConfig);
  };

  checkForLoadMore = target => {
    // if response contains more load button, bind click event to it
    const loadMoreBtn = $('[data-ajax-onclick]', target);
    if (loadMoreBtn) {
      loadMoreBtn.addEventListener('click', e => {
        e.preventDefault();
        this.ajaxCall(
          loadMoreBtn.dataset.ajaxuri,
          loadMoreBtn.dataset.ajaxconfig
        );
      });
    }
  };

  init = () => {
    import(/* webpackChunkName: 'axios' */ 'axios').then(_ => {
      this.axios = _.default;

      // start the first ajaxCall for the first element in the array
      this.ajaxCall(
        this.targets[this.count],
        this.targets[this.count].dataset.ajaxuri,
        this.targets[this.count].dataset.ajaxconfig
      );
    });
  };
}
