/** @jsx h */
import { labels } from '../config';

const SortingFieldset = ({
  pluginNamespace,
  triggerAjaxSubmit,
  sortings,
  resultsPerPage,
}) => {
  return (
    <div class="form-group-selects">
      <div class="form-group-select">
        <label for="sorting" class="sr-only">
          {labels.sorting.sortBy}
        </label>
        <select
          id="sorting"
          name={`${pluginNamespace}[sort]`}
          onChange={triggerAjaxSubmit}
        >
          {sortings.map(option => (
            <option
              value={`${option.name} ${option.sortingDirection}`}
              key={option.name}
              selected={option.selected}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {resultsPerPage.length ? (
        <div class="form-group-select">
          <label for="items-per-page" class="sr-only">
            {labels.results.perPage}
          </label>
          <select
            id="items-per-page"
            name={`${pluginNamespace}[resultsPerPage]`}
            onChange={triggerAjaxSubmit}
          >
            {resultsPerPage.map(option => (
              <option
                value={option.availablePerPage}
                key={option.availablePerPage}
                selected={option.usedResultsPerPage === option.availablePerPage}
              >
                {option.availablePerPage} {labels.results.perPage}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SortingFieldset;
