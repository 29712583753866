/** @jsx h */
import { $$ } from 'utils/dom';
import Shower from 'modules/shower';
import { Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { labels } from '../config';
import DatePicker from './Datepicker';
import Checkbox from './Checkbox';

const FilterContainer = ({
  query,
  facets,
  triggerAjaxSubmit,
  pluginNamespace,
  listView,
  toggleListView,
}) => {
  const gridFilterEl = useRef(null);
  const gridFilterButtonEl = useRef(null);
  const searchInputEl = useRef(null);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(query);

  useEffect(() => setSearchQuery(query), [query]);

  function handleInputChange() {
    if (searchInputEl && searchInputEl.current) {
      const value = searchInputEl.current.value;
      setSearchQuery(value);
    }
  }

  function handleReset() {
    const inputTexts = $$('input[type="text"]');
    const inputCheckboxes = $$('input[type="checkbox"]');
    const inputSelects = $$('input[type="select"]');

    // reset all inputs
    inputTexts.length && inputTexts.forEach(input => (input.value = ''));

    inputCheckboxes.length &&
      inputCheckboxes.forEach(input => (input.checked = false));

    inputSelects.length && inputSelects.forEach(input => (input.value = ''));

    triggerAjaxSubmit();
  }

  function handleCheckboxChange() {
    triggerAjaxSubmit();
  }

  function toggleSearch(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setSearchIsOpen(!searchIsOpen);
  }

  useEffect(() => {
    new Shower({
      target: gridFilterEl.current,
      handler: gridFilterButtonEl.current,
      easing: 'easeInOutQuart',
      duration: 400,
      initOpened: false,
    }).init();
  }, []);

  return (
    <Fragment>
      <div class="grid-controls">
        <nav class="grid-controls-search" style="display: block;">
          <h3 class="sr-only">{labels.search.searchField}</h3>
          <button
            type="button"
            class="btn btn-default prevent-udk"
            aria-controls="grid-search"
            onClick={toggleSearch}
            style={`display: ${searchIsOpen ? 'none' : 'inline-block'};`}
          >
            {labels.search.browse} <span class="genericon genericon-search" />
          </button>
          <div
            id="grid-search"
            style={`display: ${!searchIsOpen ? 'none' : 'block'};`}
            aria-expanded={searchIsOpen}
          >
            <label for="q" class="sr-only">
              {labels.search.searchField}
            </label>
            <span
              class="twitter-typeahead"
              style="position: relative; display: inline-block;"
            >
              <input
                type="text"
                name={`${pluginNamespace}[q]`}
                value={searchQuery}
                id="q"
                class="form-control tt-input active"
                autocomplete="off"
                data-main-input
                placeholder={labels.search.placeholder || ''}
                onChange={handleInputChange}
                style="width: 141px; position: relative; vertical-align: top; background-color: transparent;"
              />
            </span>
            <button type="submit" class="sr-only">
              {labels.search.submit}
            </button>
            <button
              type="button"
              class="btn btn-link prevent-udk"
              aria-controls="grid-search"
              onClick={toggleSearch}
            >
              <span class="sr-only">{labels.search.searchClose}</span>
              <span class="genericon genericon-close" />
            </button>
          </div>
        </nav>

        <div class="grid-controls-layout">
          <h3 class="sr-only" id="gcl-headline-0">
            {labels.search.gridLayout}
          </h3>
          <ul aria-describedby="gcl-headline-0">
            <li>
              <button
                class="btn btn-default"
                type="button"
                disabled={!listView}
                onClick={() => toggleListView(false)}
              >
                <span class="ui-grid" />
                <span class="sr-only">{labels.search.showGrid}</span>
              </button>
            </li>
            <li>
              <button
                class="btn btn-default"
                type="button"
                disabled={listView}
                onClick={() => toggleListView(true)}
              >
                <span class="ui-list" />
                <span class="sr-only">{labels.search.showList}</span>
              </button>
            </li>
          </ul>
        </div>

        <button
          type="button"
          class="btn btn-default grid-controls-filter prevent-udk"
          aria-controls="gfilters-0"
          ref={gridFilterButtonEl}
        >
          {labels.search.filter} <span class="caret" />
        </button>

        {facets.map(facet => {
          if (facet.type === 'dateRange') {
            return (
              <DatePicker
                key={facet.name}
                facet={facet}
                triggerAjaxSubmit={triggerAjaxSubmit}
                pluginNamespace={pluginNamespace}
              />
            );
          } else {
            return '';
          }
        })}
        <button type="reset" class="btn btn-default" onClick={handleReset}>
          {labels.faceting.resetQuery}
        </button>
      </div>

      <nav class="grid-filters" id="gfilters-0" ref={gridFilterEl}>
        <h3 class="sr-only">{labels.search.filter}</h3>
        {facets.map(facet => {
          if (facet.type === 'dateRange') {
            return '';
          }
          return (
            <Fragment key={facet.name}>
              <h4 id="filter-type">{facet.label}</h4>
              <ul aria-describedby="filter-type">
                {facet.options.map(option => (
                  <Checkbox
                    key={option.value}
                    option={option}
                    filterName={facet.name}
                    handleChange={handleCheckboxChange}
                    pluginNamespace={pluginNamespace}
                  />
                ))}
              </ul>
            </Fragment>
          );
        })}
      </nav>
    </Fragment>
  );
};

export default FilterContainer;
