import { $, $$ } from 'utils/dom';
import { Accordion } from '@3pc/accordion';

const accordionItems = $$('.accordion__item');

const initAccordion = container => {
  const handler = $('.accordion__content-trigger', container);
  const target = $('.accordion__content-panel', container);
  const targetInner = $('.accordion__content-panel-inner', target);

  if (!handler || !target || !targetInner) return;

  const accordion = new Accordion({
    target,
    targetInner,
    handler,
    easing: 'easeInOutQuart',
    duration: 400,
    initOpened: false,
    openClassName: 'is-open',
  });
  accordion.init();
};

// loop over each accordion item and initialize it
accordionItems.forEach(accordionItem => {
  return initAccordion(accordionItem);
});
