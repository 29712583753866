import { $, $$, insertHTML } from 'utils/dom';

const eventAsideContainer = $('#c-when');
if (eventAsideContainer) {
  const events = $$('.date', eventAsideContainer);
  const docLang = document.documentElement.lang;
  const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;
  const labels = {
    showMoreBtn: {
      de: 'Alle Termine',
      en: 'All Events',
    },
    showLessBtn: {
      de: 'Weniger Termine',
      en: 'Less Events',
    },
  };
  const showMoreBtn = `<button class="event__show-more-button btn btn-default">${labels.showMoreBtn[lang]}</button>`;
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  if ((isMobile && events.length > 3) || (!isMobile && events.length > 5)) {
    // only show the first 3 events with on mobile devices and hide the rest
    // on desktop devices show 5 events and hide the rest
    const maxEvents = isMobile ? 3 : 5;

    events.forEach((event, index) => {
      return (event.hidden = index >= maxEvents);
    });

    // render show more button
    insertHTML(eventAsideContainer, 'beforeend', showMoreBtn);

    const showMoreButton = $('.event__show-more-button', eventAsideContainer);
    let isShowAllState = false;

    showMoreButton &&
      showMoreButton.addEventListener('click', () => {
        // if only the maxEvents are shown, show all events
        if (!isShowAllState) {
          isShowAllState = true;
          showMoreButton.innerText = labels.showLessBtn[lang];
          return events.forEach(event => (event.hidden = false));
        }

        // if all events are shown, show only the maxEvents
        isShowAllState = false;
        events.forEach((event, index) => {
          showMoreButton.innerText = labels.showMoreBtn[lang];
          return (event.hidden = index >= maxEvents);
        });
      });
  }
}
