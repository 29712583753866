/** @jsx h */
import { Fragment } from 'preact';
import Pagination from './Pagination';

const TeaserEvent = ({ list, pagination, loadMore, pluginNamespace }) => {
  function handleLoadMoreClick() {
    loadMore();
  }

  return (
    <Fragment>
      <div class="row">
        {list.map((item, index) => (
          <article class="grid-item" key={`person-${index}`}>
            <a href={item.url}>
              <div class="circle-image">
                {item.image ? (
                  <img
                    src={item.image.src}
                    alt={item.image.alt}
                    width="631"
                    height="631"
                  />
                ) : (
                  <img
                    src="/public/_processed_/5/4/csm_grauerHintergrund_b40635c7aa.jpg"
                    alt=""
                    width="631"
                    height="631"
                  />
                )}
              </div>

              <header>
                <h2>{item.title}</h2>
                <p>
                  {item.subtitle}
                  <br />
                  {item.footer}
                </p>
              </header>
            </a>
          </article>
        ))}
      </div>

      <Pagination
        pagination={pagination}
        triggerLoadMore={handleLoadMoreClick}
        pluginNamespace={pluginNamespace}
      />
    </Fragment>
  );
};

export default TeaserEvent;
