/** @jsx h */
import { labels } from '../config';

const Loading = () => (
  <div className="animation-box" aria-hidden="true">
    <div className="loader">
      <svg
        className="circular"
        viewBox="25 25 50 50"
        aria-labelledby="search-ani-title"
        role="img"
      >
        <title id="search-ani-title">{labels.faceting.filterClose}</title>
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  </div>
);

export default Loading;
