/** @jsx h */
import { labels } from '../config';
import TeaserGeneral from '../components/TeaserGeneral';
import TeaserEvent from './TeaserEvent';
import TeaserContact from './TeaserContact';
import TeaserStudy from './TeaserStudy';

const ResultContainer = ({
  searchType,
  allResultCount,
  list,
  pagination,
  loadMore,
  pluginNamespace,
  handleSubmit,
  facets,
}) => {
  switch (searchType) {
    case 'main':
      return (
        <TeaserGeneral
          allResultCount={allResultCount}
          list={list}
          pagination={pagination}
          loadMore={loadMore}
          pluginNamespace={pluginNamespace}
          triggerAjaxSubmit={handleSubmit}
          facets={facets}
        />
      );
    case 'calendar':
      return (
        <TeaserEvent
          allResultCount={allResultCount}
          list={list}
          pagination={pagination}
          loadMore={loadMore}
          pluginNamespace={pluginNamespace}
        />
      );
    case 'person':
      return (
        <TeaserContact
          allResultCount={allResultCount}
          list={list}
          pagination={pagination}
          loadMore={loadMore}
          pluginNamespace={pluginNamespace}
        />
      );
    case 'study':
      return (
        <TeaserStudy
          allResultCount={allResultCount}
          list={list}
          pagination={pagination}
          loadMore={loadMore}
          pluginNamespace={pluginNamespace}
        />
      );
    default:
      return labels.search.unavailable;
  }
};

export default ResultContainer;
