import DatePickerDay from './datepicker-day';

const docLang = document.documentElement.lang;
const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;

export default class DatePicker {
  constructor(props) {
    this.dayLabels = [
      {
        de: 'Sonntag',
        en: 'Sunday',
      },
      {
        de: 'Montag',
        en: 'Monday',
      },
      {
        de: 'Dienstag',
        en: 'Tuesday',
      },
      {
        de: 'Mittwoch',
        en: 'Wednesday',
      },
      {
        de: 'Donnerstag',
        en: 'Thursday',
      },
      {
        de: 'Freitag',
        en: 'Friday',
      },
      {
        de: 'Samstag',
        en: 'Saturday',
      },
    ];
    this.monthLabels = [
      {
        de: 'Januar',
        en: 'January',
      },
      {
        de: 'Februar',
        en: 'February',
      },
      {
        de: 'März',
        en: 'March',
      },
      {
        de: 'April',
        en: 'April',
      },
      {
        de: 'Mai',
        en: 'May',
      },
      {
        de: 'Juni',
        en: 'June',
      },
      {
        de: 'Juli',
        en: 'July',
      },
      {
        de: 'August',
        en: 'August',
      },
      {
        de: 'September',
        en: 'September',
      },
      {
        de: 'Oktober',
        en: 'October',
      },
      {
        de: 'November',
        en: 'November',
      },
      {
        de: 'Dezember',
        en: 'December',
      },
    ];

    this.messageCursorKeys = 'Cursor keys can navigate dates';

    this.inputNode = props.inputNode;
    this.dialogNode = props.dialogNode;
    this.onDaySet = props.onDaySet;
    this.disablePastDates = props.disablePastDates || false;
    this.disablePastMonths = props.disablePastMonths || false;

    this.MonthYearNode = this.dialogNode.querySelector(
      '.datepicker__month-year'
    );

    this.prevYearNode = this.dialogNode.querySelector('.datepicker__prev-year');
    this.prevMonthNode = this.dialogNode.querySelector(
      '.datepicker__prev-month'
    );
    this.nextMonthNode = this.dialogNode.querySelector(
      '.datepicker__next-month'
    );
    this.nextYearNode = this.dialogNode.querySelector('.datepicker__next-year');

    this.okButtonNode = this.dialogNode.querySelector('button[value="ok"]');
    this.cancelButtonNode = this.dialogNode.querySelector(
      'button[value="cancel"]'
    );

    this.tbodyNode = this.dialogNode.querySelector(
      'table.datepicker__dates tbody'
    );

    this.lastRowNode = null;

    this.days = [];

    this.focusDay = new Date();
    this.selectedDay = new Date();
    this.activeDate = null;

    this.isMouseDownOnBackground = false;

    this.keyCode = Object.freeze({
      TAB: 9,
      ENTER: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
    });
  }

  init = () => {
    if (this.okButtonNode) {
      this.okButtonNode.addEventListener(
        'click',
        this.handleOkButton.bind(this)
      );
      this.okButtonNode.addEventListener(
        'keydown',
        this.handleOkButton.bind(this)
      );
    }

    if (this.cancelButtonNode) {
      this.cancelButtonNode.addEventListener(
        'click',
        this.handleCancelButton.bind(this)
      );
      this.cancelButtonNode.addEventListener(
        'keydown',
        this.handleCancelButton.bind(this)
      );
    }

    this.prevMonthNode.addEventListener(
      'click',
      this.handlePreviousMonthButton.bind(this)
    );
    this.nextMonthNode.addEventListener(
      'click',
      this.handleNextMonthButton.bind(this)
    );
    this.prevYearNode.addEventListener(
      'click',
      this.handlePreviousYearButton.bind(this)
    );
    this.nextYearNode.addEventListener(
      'click',
      this.handleNextYearButton.bind(this)
    );

    this.prevMonthNode.addEventListener(
      'keydown',
      this.handlePreviousMonthButton.bind(this)
    );
    this.nextMonthNode.addEventListener(
      'keydown',
      this.handleNextMonthButton.bind(this)
    );
    this.prevYearNode.addEventListener(
      'keydown',
      this.handlePreviousYearButton.bind(this)
    );

    this.nextYearNode.addEventListener(
      'keydown',
      this.handleNextYearButton.bind(this)
    );

    // Create Grid of Dates

    this.tbodyNode.innerHTML = '';
    let index = 0;
    for (let i = 0; i < 6; i++) {
      const row = this.tbodyNode.insertRow(i);
      this.lastRowNode = row;
      row.classList.add('datepicker__date-row');
      for (let j = 0; j < 7; j++) {
        const cell = document.createElement('td');
        cell.classList.add('datepicker__date-cell');
        const cellButton = document.createElement('button');
        cellButton.classList.add('datepicker__button-date');
        cellButton.setAttribute('type', 'button');
        cell.appendChild(cellButton);
        row.appendChild(cell);
        const dpDay = new DatePickerDay(
          cellButton,
          this,
          index,
          i,
          j,
          this.disablePastDates,
          this.disablePastMonths
        );
        dpDay.init();
        this.days.push(dpDay);
        index++;
      }
    }

    this.updateGrid();
    this.setFocusDay();
  };

  updateGrid = () => {
    let i;
    let flag;
    const fd = this.focusDay;

    this.MonthYearNode.innerHTML =
      this.monthLabels[fd.getMonth()][lang] + ' ' + fd.getFullYear();

    const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
    const daysInMonth = new Date(
      fd.getFullYear(),
      fd.getMonth() + 1,
      0
    ).getDate();
    const dayOfWeek = firstDayOfMonth.getDay();

    firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);

    const d = new Date(firstDayOfMonth);
    for (i = 0; i < this.days.length; i++) {
      flag = d.getMonth() !== fd.getMonth();
      this.days[i].updateDay(flag, d);
      if (
        d.getFullYear() === this.selectedDay.getFullYear() &&
        d.getMonth() === this.selectedDay.getMonth() &&
        d.getDate() === this.selectedDay.getDate()
      ) {
        this.days[i].domNode.setAttribute('aria-selected', 'true');
        this.days[i].domNode.parentElement.classList.add('is-current-date');
      } else {
        this.days[i].domNode.parentElement.classList.remove('is-current-date');
      }
      d.setDate(d.getDate() + 1);
    }

    if (dayOfWeek + daysInMonth < 36) {
      this.hideLastRow();
    } else {
      this.showLastRow();
    }
  };

  hideLastRow = () => {
    this.lastRowNode.setAttribute('hidden', 'true');
  };

  showLastRow = () => {
    this.lastRowNode.removeAttribute('hidden', 'true');
  };

  setFocusDay = flag => {
    if (typeof flag !== 'boolean') {
      flag = true;
    }

    const fd = this.focusDay;

    function checkDay(d) {
      d.domNode.setAttribute('tabindex', '-1');
      if (
        d.day.getDate() === fd.getDate() &&
        d.day.getMonth() === fd.getMonth() &&
        d.day.getFullYear() === fd.getFullYear()
      ) {
        d.domNode.setAttribute('tabindex', '0');
        if (flag) {
          d.domNode.focus();
        }
      }
    }

    this.days.forEach(checkDay.bind(this));
  };

  updateDay = day => {
    const d = this.focusDay;
    this.focusDay = day;
    if (
      d.getMonth() !== day.getMonth() ||
      d.getFullYear() !== day.getFullYear()
    ) {
      this.updateGrid();
      this.setFocusDay();
    }
  };

  getDaysInLastMonth = () => {
    const fd = this.focusDay;
    const lastDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 0);
    return lastDayOfMonth.getDate();
  };

  getDaysInMonth = () => {
    const fd = this.focusDay;
    const lastDayOfMonth = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
    return lastDayOfMonth.getDate();
  };

  show = () => {
    this.dialogNode.style.display = 'block';
    this.dialogNode.style.zIndex = 2;

    this.getDateInput();
    this.updateGrid();
    this.setFocusDay();
  };

  isOpen = () => window.getComputedStyle(this.dialogNode).display !== 'none';

  hide = () => {
    // this.dialogNode.style.display = 'none';

    this.hasFocusFlag = false;
    this.setFocus();
  };

  handleBackgroundMouseDown = event => {
    if (!this.dialogNode.contains(event.target)) {
      this.isMouseDownOnBackground = true;

      if (this.isOpen()) {
        this.hide();
        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  handleBackgroundMouseUp = () => {
    this.isMouseDownOnBackground = false;
  };

  handleOkButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.setTextboxDate(null, event);

            this.hide();
            flag = true;
            break;

          case this.keyCode.TAB:
            if (!event.shiftKey) {
              this.prevYearNode.focus();
              flag = true;
            }
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }
        break;

      case 'click':
        this.setTextboxDate(null, event);
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleCancelButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }
        break;

      case 'click':
        this.hide();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleNextYearButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToNextYear();
            this.setFocusDay(false);
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToNextYear();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handlePreviousYearButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToPreviousYear();
            this.setFocusDay(false);
            flag = true;
            break;

          case this.keyCode.TAB:
            if (event.shiftKey) {
              this.okButtonNode && this.okButtonNode.focus();
              flag = true;
            }
            break;

          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToPreviousYear();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleNextMonthButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToNextMonth();
            this.setFocusDay(false);
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToNextMonth();
        this.setFocusDay(false);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handlePreviousMonthButton = event => {
    let flag = false;

    switch (event.type) {
      case 'keydown':
        switch (event.keyCode) {
          case this.keyCode.ESC:
            this.hide();
            flag = true;
            break;

          case this.keyCode.ENTER:
          case this.keyCode.SPACE:
            this.moveToPreviousMonth();
            this.setFocusDay(false);
            flag = true;
            break;

          default:
            break;
        }

        break;

      case 'click':
        this.moveToPreviousMonth();
        this.setFocusDay(false);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  moveToNextYear = () => {
    this.focusDay.setFullYear(this.focusDay.getFullYear() + 1);
    this.updateGrid();
    this.setActiveDate();
  };

  moveToPreviousYear = () => {
    this.focusDay.setFullYear(this.focusDay.getFullYear() - 1);
    this.updateGrid();
    this.setActiveDate();
  };

  moveToNextMonth = () => {
    this.focusDay.setMonth(this.focusDay.getMonth() + 1);
    this.updateGrid();
    this.setActiveDate();
  };

  moveToPreviousMonth = () => {
    this.focusDay.setMonth(this.focusDay.getMonth() - 1);
    this.updateGrid();
    this.setActiveDate();
  };

  moveFocusToDay = day => {
    const d = this.focusDay;

    this.focusDay = day;

    if (
      d.getMonth() !== this.focusDay.getMonth() ||
      d.getYear() !== this.focusDay.getYear()
    ) {
      this.updateGrid();
    }
    this.setFocusDay();
  };

  moveFocusToNextDay = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() + 1);
    this.moveFocusToDay(d);
  };

  moveFocusToNextWeek = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() + 7);
    this.moveFocusToDay(d);
  };

  moveFocusToPreviousDay = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() - 1);
    this.moveFocusToDay(d);
  };

  moveFocusToPreviousWeek = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() - 7);
    this.moveFocusToDay(d);
  };

  moveFocusToFirstDayOfWeek = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() - d.getDay());
    this.moveFocusToDay(d);
  };

  moveFocusToLastDayOfWeek = () => {
    const d = new Date(this.focusDay);
    d.setDate(d.getDate() + (6 - d.getDay()));
    this.moveFocusToDay(d);
  };

  setTextboxDate = (day, event) => {
    this.setActiveDate(day);

    if (day) {
      this.setDate(day);
    } else {
      this.setDate(this.focusDay);
    }
  };

  setActiveDate = day => {
    if (day) this.activeDate = day;
    const dateButtons = [
      ...this.dialogNode.querySelectorAll('.datepicker__button-date'),
    ];

    if (dateButtons.length && this.activeDate) {
      dateButtons.forEach(dateBtn => {
        const date = new Date(dateBtn.dataset.date);
        dateBtn.parentElement.classList.remove('is-active');
        if (
          date.getFullYear() === this.activeDate.getFullYear() &&
          date.getMonth() === this.activeDate.getMonth() &&
          date.getDate() === this.activeDate.getDate()
        ) {
          dateBtn.parentElement.classList.add('is-active');
        }
      });
    }
  };

  getDateInput = () => {
    const parts = this.getDate().split('/');

    if (
      parts.length === 3 &&
      Number.isInteger(parseInt(parts[0], 10)) &&
      Number.isInteger(parseInt(parts[1], 10)) &&
      Number.isInteger(parseInt(parts[2], 10))
    ) {
      this.focusDay = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[0], 10) - 1,
        parseInt(parts[1], 10)
      );
      this.selectedDay = new Date(this.focusDay);
    } else {
      // If not a valid date (MM/DD/YY) initialize with todays date
      this.focusDay = new Date();
      this.selectedDay = new Date(0, 0, 1);
    }
  };

  getDateForButtonLabel = (year, month, day) => {
    if (
      typeof year !== 'number' ||
      typeof month !== 'number' ||
      typeof day !== 'number'
    ) {
      this.selectedDay = this.focusDay;
    } else {
      this.selectedDay = new Date(year, month, day);
    }

    let label = this.dayLabels[this.selectedDay.getDay()][lang];
    label += ' ' + this.monthLabels[this.selectedDay.getMonth()][lang];
    label += ' ' + this.selectedDay.getDate();
    label += ', ' + this.selectedDay.getFullYear();
    return label;
  };

  setFocus = () => {
    this.buttonNode && this.buttonNode.focus();
  };

  setDate = day => {
    this.inputNode.value = `${day.getFullYear()}-${
      day.getMonth() + 1 < 10 ? '0' : ''
    }${day.getMonth() + 1}-${day.getDate() < 10 ? '0' : ''}${day.getDate()}`;
    this.handleSetDate(day);
  };

  handleSetDate = day => {
    this.onDaySet && this.onDaySet(day);
  };

  getDate = () => this.inputNode.value;

  resetActiveDate = () => {
    const dateButtons = [
      ...this.dialogNode.querySelectorAll('.datepicker__button-date'),
    ];
    dateButtons.length &&
      dateButtons.forEach(dateBtn =>
        dateBtn.parentElement.classList.remove('is-active')
      );

    this.focusDay = new Date();
    this.selectedDay = new Date();
    this.activeDate = null;
  };
}

// new Datepicker({
//   inputNode: $('input'),
//   dialogNode: $('.datepicker__dialog'),
// }).init();
