/** @jsx h */
import { $ } from 'utils/dom';
import {
  labels,
  endpoint,
  showMaininput,
  autoSuggest,
  searchType,
} from '../config';
import { Fragment } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { fetchSearchData, replaceUrl } from '../helpers/helpers';
import { searchStore } from '../store/store';
import SearchHeader from '../components/SearchHeader';
import ResultContainer from '../components/ResultContainer';
import GridContainer from '../components/GridContainer';
import Loading from '../components/Loading';
import Autosuggest from '../helpers/Autosuggest';

const GlobalSearch = ({ initialSearchData, pluginNamespace }) => {
  // set default states
  const [searchData, setSearchData] = useState(initialSearchData);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [resultIsEmpty, setResultIsEmpty] = useState(
    searchData.allResultCount === 0
  );
  const [inputQuery, setInputQuery] = useState(searchData.arguments);
  const [resultList, setResultList] = useState(
    searchData.documents.list.results
  );
  const [autoSuggestClass, setAutoSuggestClass] = useState(null);
  const formEl = useRef(null);

  // get setSearchJson function from the store
  const setSearchJson = searchStore(state => state.setSearchJson);

  function resetFilters(formData) {
    // if its a new quey, we need to reset all previous facets
    for (const key of formData.keys()) {
      // clear all filter except input query
      if (key !== `${pluginNamespace}[q]`) formData.delete(key);
    }

    formData.delete(`${pluginNamespace}[filter][]`);
  }

  function handleSubmit(e) {
    e && e.preventDefault();
    triggerSearch();
  }

  function handleLoadMore() {
    triggerSearch(true);
  }

  function triggerSearch(isLoadMore = false, query = '') {
    const formData = new FormData(formEl.current);

    // cancel on-going autosuggest call
    if (autoSuggestClass) {
      autoSuggestClass.cancelAjax(true);
    }

    // update query, if one was passed into this function
    if (query) formData.set(`${pluginNamespace}[q]`, query);
    const formQuery = formData.get(`${pluginNamespace}[q]`);

    // check if user is searching with a new query
    if (formQuery && inputQuery) {
      const isNewQuery = formQuery !== inputQuery;
      if (isNewQuery) resetFilters(formData);
    } else if ((formQuery && !inputQuery) || (!formQuery && inputQuery)) {
      resetFilters(formData);
    }

    setInputQuery(formQuery);
    if (formData.get(`${pluginNamespace}[q]`) === '') {
      formData.delete(`${pluginNamespace}[q]`);
    }
    if (formData.get(`${pluginNamespace}[sort]`) === '') {
      formData.delete(`${pluginNamespace}[sort]`);
    }

    if (!isLoadMore && formData.get(`${pluginNamespace}[page]`)) {
      formData.delete(`${pluginNamespace}[page]`);
    }

    getSearchData(formData, isLoadMore);
  }

  function getSearchData(formData, isLoadMore = false) {
    setIsLoading(true);
    setIsError(false);
    fetchSearchData(endpoint, formData)
      .then(({ data }) => {
        const resultData = data.content.colPos0[0].content.data;
        const hasNoResults = resultData.allResultCount === 0;
        const resultDataList = resultData.documents.list.results;

        // if there are no results, show no-result text
        if (hasNoResults) {
          setResultIsEmpty(true);
          setResultList([]);
          setSearchJson(resultData);
          setSearchData(resultData);
        } else {
          setResultIsEmpty(false);
          setSearchData(resultData);

          if (isLoadMore) {
            // append next item into array, when is loadmore (cause we dont want to replace the prev items)
            setResultList([...resultList, ...resultDataList]);

            /*
             * onLoadMore, we dont want to replace the whole searchJson (from the store)
             * We only need to update the pagination number and the result count
             * and append the new incoming result list to the old one
             */
            const searchJson = searchStore.getState().searchJson;
            const searchJsonCount = searchJson.documents.list.count;

            // append new result to the old result
            searchJson.documents.list.results = [
              ...resultList,
              ...resultDataList,
            ];

            // update searchJson count
            searchJson.documents.list.count =
              searchJsonCount + resultData.documents.list.count;

            // update current pagination
            searchJson.documents.pagination.current =
              resultData.documents.pagination.current;

            // save it in our searchStore (needed for popstate later)
            setSearchJson(searchJson);
          } else {
            // replace the whole list
            setResultList(resultDataList);

            // save response json in our searchStore (needed for popstate later)
            setSearchJson(resultData);
          }
        }

        // add search params to the url
        replaceUrl(
          process.env.NODE_ENV === 'production' ? endpoint : '/api/v1/search',
          resultData.currentSearch,
          formData,
          pluginNamespace
        );

        setIsLoading(false);
      })
      .catch(error => {
        console.error('Search Error:', error);
        setIsLoading(false);
        setIsError(true);
      });
  }

  // init autosuggest
  useEffect(() => {
    if (autoSuggest) {
      const form = formEl.current;
      const searchInput = $('[data-main-input]', form);

      if (form && searchInput) {
        const autoSuggestObj = new Autosuggest({
          // input which triggers solr
          input: searchInput,
          // form element which contains the input
          form,
          // [optional] min length of text until we fire the ajax-request (0 is default)
          textMinLength: 2,
          // [optional] set true, if you want to enable typeahead
          typeahead: false,
          // [optional] prevent AutoSuggest from submitting the form (e.g. if you want to trigger ajax instead) (default is false)
          preventSubmit: true,
          // [optional] allow empty submit even if input is empty (default is false)
          allowEmptySubmit: true,
          // [optional] submit form if user clicks on a suggestion button (default is false)
          submitOnSuggestionClick: false,
          onSelecting: () => triggerSearch(),
        });
        autoSuggestObj.init();

        setAutoSuggestClass(autoSuggestObj);
      }
    }
  }, []);

  return (
    <Fragment>
      {/* show loading animation, while fetching */}
      {isLoading && <Loading />}
      <form
        action="#"
        onSubmit={handleSubmit}
        ref={formEl}
        data-suggest={autoSuggest}
      >
        {/* Global search has a different HTML structure thats why we need this if condition */}
        {searchType === 'main' ? (
          <Fragment>
            {showMaininput && (
              <SearchHeader
                query={inputQuery}
                pluginNamespace={pluginNamespace}
                triggerAjaxSubmit={handleSubmit}
                sortings={searchData.sorting}
                resultsPerPage={searchData.resultsPerPage}
              />
            )}

            {resultIsEmpty ? (
              <div className="search-section__empty">
                {labels.results.nothingFound.replace('@searchWord', inputQuery)}
              </div>
            ) : isError ? (
              <div className="search-section__error">
                {labels.search.failed}
              </div>
            ) : (
              <ResultContainer
                searchType={searchType}
                allResultCount={searchData.allResultCount}
                list={resultList}
                pagination={searchData.documents.pagination}
                loadMore={handleLoadMore}
                pluginNamespace={pluginNamespace}
                handleSubmit={handleSubmit}
                facets={searchData.facets}
              />
            )}
          </Fragment>
        ) : (
          <GridContainer
            inputQuery={inputQuery}
            pluginNamespace={pluginNamespace}
            searchData={searchData}
            handleSubmit={handleSubmit}
            handleLoadMore={handleLoadMore}
            resultIsEmpty={resultIsEmpty}
            isError={isError}
            resultList={resultList}
          />
        )}
      </form>
    </Fragment>
  );
};

export default GlobalSearch;
