import axios from 'axios';

const history = window.history;

export const fetchSearchData = async (endpoint, formData = '') => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  // cant use post request in local env, so we use get instead while developing
  if (process.env.NODE_ENV !== 'production') {
    const response = await axios.get(endpoint);
    return response;
  } else {
    const response = await axios.post(endpoint, formData, config);
    return response;
  }
};

export const replaceUrl = (
  endpoint,
  currentSearchUrl,
  formData,
  pluginNamespace
) => {
  // get endpoint which is an absulute url, so we have to remove the base url from the string.
  // Then, get currentSearch url and remove the api prefix
  // if the searchUrl is empty after the removal of the prefix,
  // it means that there are no search-parameter to save. So we dont have to do anything
  const endpointString = endpoint.substring(endpoint.indexOf('/api/'));
  const searchUrl = currentSearchUrl.replace(endpointString, '');
  const pageParam = formData.get(`${pluginNamespace}[page]`);
  if (searchUrl) {
    const url = !pageParam
      ? searchUrl
      : `${searchUrl}&search[page]=${pageParam}`;
    history.replaceState({}, '', url);
  } else if (pageParam) {
    history.replaceState(
      {},
      '',
      `${window.location.pathname}?search[page]=${pageParam}`
    );
  } else {
    history.replaceState({}, '', window.location.pathname);
  }
};

export const getConvertedLetter = letter => {
  const specialLetters = [
    'æ',
    'ç',
    '[è-ë]',
    '[ì-ï]',
    'ð',
    'ñ',
    '[ò-öø]',
    '÷',
    '[ù-ü]',
    '[ý-ÿ]',
    'ß',
    'Ş',
    '[à-å]',
  ];
  const normalLetters = [
    'ae',
    'c',
    'e',
    'i',
    'd',
    'n',
    'o',
    'x',
    'u',
    'y',
    's',
    's',
    'a',
  ];

  // convert special characters to normal character
  let convertedLetter = letter;
  specialLetters.forEach((specialLetter, index) => {
    convertedLetter = convertedLetter
      .replace(new RegExp(specialLetter, 'gi'), normalLetters[index])
      .toUpperCase();
  });

  return convertedLetter;
};

export const compare = (a, b) => {
  if (a.localeCompare(b, undefined, { numeric: true }) > 0) {
    return 1;
  } else if (a.localeCompare(b, undefined, { numeric: true }) < 0) {
    return -1;
  } else {
    a.localeCompare(b, undefined, { numeric: true });
  }
};

/**
 * This functions expect an array of objects, where you want to sort the objects by a given key of the object
 * Returns an array of objects sort by the given key.
 * @param {array} array - An array of objects, you want to get sorted by letters
 * @param {string} key - The key of the object you want to use for sorting
 */
export const sortArray = (array, key) =>
  array
    .sort((a, b) => compare(a[key], b[key])) // first, sort by the whole word
    .sort((a, b) => compare(a[key][0], b[key][0])); // then make sure, special characters comes after normal letters;

/**
 * This functions expect an array of objects, where you want to sort the objects by letter
 * by a given key of the object.
 * Returns an array of objects grouped by the letters.
 * @param {array} array - An array of objects, you want to get sorted by letters
 * @param {string} key - The key of the object you want to use for sorting
 */
export const groupedByAlphabet = (array, key) => {
  const groupedData = array.reduce((r, e) => {
    // get first letter of name of current element and convert special characters
    const firstLetter = getConvertedLetter(e[key][0]);

    // if there is no property in accumulator with this letter create it
    if (!r[firstLetter]) {
      r[firstLetter] = { alphabet: firstLetter, records: [e] };
    } else {
      // if there is push current element to children array for that letter
      r[firstLetter].records.push(e);
    }

    // return accumulator
    return r;
  }, {});

  return Object.values(groupedData);
};

/**
 * This functions expect an array and will group every follow 3 items into a letter group
 * by a given alphabet of the object.
 * Returns an array of objects grouped by the letters.
 * @param {array} array - An array of objects, you want to get grouped
 * @param {string} alphabet - The key of the object which should be the alphabet of the object
 * @param {string} records - The key of the object which should hold the children array of the alphabet
 */
export const groupedRangeByAlphabet = (array, alphabet, records) => {
  const groupedArray = [];
  let groupedRecords = [];
  let key = '';

  array.forEach((item, index) => {
    groupedRecords.push(...item[records]);

    if (index % 3 === 0) {
      key = item[alphabet];
    }

    if (index % 3 === 2 || index + 1 === array.length) {
      key = key === item[alphabet] ? key : `${key}-${item[alphabet]}`;
      groupedArray.push({
        key,
        groupedRecords,
      });
      groupedRecords = [];
    }
  });

  return groupedArray;
};

/**
 * This functions expect an Javascript Date Object.
 * Returns an object of formatted day, month, year and the Date Object itself.
 * @param {dateObj} Date - New Date() Object
 */
export const dateFormatter = dateObj => {
  const year = dateObj.getFullYear();
  const dateMonth = dateObj.getMonth() + 1;
  const month = dateMonth < 10 ? `0${dateMonth}` : dateMonth;
  const dateDay = dateObj.getDate();
  const day = dateDay < 10 ? `0${dateDay}` : dateDay;

  return {
    dateObj,
    day,
    month,
    year,
  };
};

export const fullDate = date =>
  `${date.getFullYear()}${date.getMonth() + 1 < 10 ? '0' : ''}${
    date.getMonth() + 1
  }${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;

export const getFormattedDate = (date, valueTemplate) => {
  const formattedStartDate = fullDate(date);

  return valueTemplate.replace('___FROM___', `${formattedStartDate}0000`);
};
