import { $ } from 'utils/dom';

export const $search = $('#search-app');
export const labels = $search ? JSON.parse($search.dataset.labels) : {};
export const endpoint = $search && $search.dataset.endpoint;
export const initialFilters =
  $search &&
  JSON.parse($search.dataset.initialFilters.replaceAll('&quot;', '"'));
export const showMaininput =
  $search && $search.dataset.showMaininput === 'true';
export const searchType = $search && $search.dataset.searchType;
export const autoSuggest = $search ? $search.dataset.suggest : null;
export const showCount = $search && $search.dataset.showCount === 'true';
export const showFacets = $search && $search.dataset.showFacets === 'true';
export const showSort = $search && $search.dataset.showSort === 'true';
