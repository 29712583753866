/** @jsx h */
import { Fragment } from 'preact';
import Pagination from './Pagination';

const TeaserStudy = ({ list, pagination, loadMore, pluginNamespace }) => {
  function handleLoadMoreClick() {
    loadMore();
  }

  return (
    <Fragment>
      <div class="row">
        {list.map((item, index) => (
          <article class="grid-item" key={`teaser-study-${index}`}>
            <a href={item.url}>
              {item.image ? (
                <div class="article-image">
                  <img
                    src={item.image.src}
                    alt={item.image.alt}
                    title={item.image.title}
                    width="605"
                    height="404"
                  />
                </div>
              ) : (
                ''
              )}

              <header>
                <h2>{item.title}</h2>
                <div class="meta-info">{item.subtitle}</div>
              </header>
              <p>{item.teaser}</p>
            </a>
          </article>
        ))}
      </div>

      <Pagination
        pagination={pagination}
        triggerLoadMore={handleLoadMoreClick}
        pluginNamespace={pluginNamespace}
      />
    </Fragment>
  );
};

export default TeaserStudy;
