import { $, $$, closest, insertHTML } from 'utils/dom';

export default class Autosuggest {
  TEMPLATE_SELECTOR = '.autocomplete-suggestions';
  ITEM_BTN_SELECTOR = '.autocomplete-suggestion-btn';
  TYPEAHEAD_SELECTOR = '.typeahead-hint';

  constructor(props) {
    this.input = props.input;
    this.form = props.form;
    this.typeahead = props.typeahead || false;
    this.textMinLength = props.textMinLength || 0;
    this.preventSubmit = props.preventSubmit || false;
    this.allowEmptySubmit = props.allowEmptySubmit || false;
    this.onlySubmittingIfSuggestion = props.onlySubmittingIfSuggestion || false;
    this.submitOnSuggestionClick = props.submitOnSuggestionClick || false;
    this.autoSuggestTarget = props.autoSuggestTarget || this.input;
    this.onSubmitting = props.onSubmitting;
    this.onSelecting = props.onSelecting;
    this.onTyping = props.onTyping;
    this.pluginNamespace = this.form.dataset.txSolrPluginNamespace || 'tx_solr';
    this.serviceUrl = this.form.dataset.suggest;
    this.axios = null;
    this.query = null;
    this.response = null;
    this.hasSuggestions = false;
    this.isLoading = false;
    this.axioxSource = null;
  }

  handleTyping = () => {
    // callback on input (while user is typing)
    this.onTyping && this.onTyping(this.response);
  };

  handleSubmit = () => {
    // callback on submit (enter)
    this.onSubmitting && this.onSubmitting(this.response);
  };

  handleSelect = query => {
    // callback if user select a suggestion
    this.onSelecting && this.onSelecting(query);
  };

  setQuery = query => {
    // update our query
    this.query = query || this.input.value;
  };

  setQueryToInput = query => {
    // set actual query as value of our inputfield
    this.input.value = query;
  };

  setSuggestionState = () =>
    // check if the response has suggestions or not
    typeof this.response.suggestions !== 'undefined';

  cancelAjax = (stopRefetch = false) => {
    if (this.axioxSource) {
      this.axioxSource.cancel(stopRefetch);
      this.destroySuggestionList();
    }
  };

  triggerAjax = () => {
    // set query seperator, if serviceUrl already has params in it
    const paramSeperator = this.serviceUrl
      ? this.serviceUrl.indexOf('?') > 0
        ? '&'
        : '?'
      : '?';
    // if serviceUrl is a relative url, set window.location.origin as base url, if its an absolute url leave this variable empty
    const baseUrl =
      this.serviceUrl.startsWith('http') === 0 ||
      this.serviceUrl.startsWith('https') === 0
        ? ''
        : window.location.origin;
    // build the url for our ajax call
    const ajaxUrl = `${baseUrl}${this.serviceUrl}${paramSeperator}${this.pluginNamespace}[callback]=&${this.pluginNamespace}[queryString]=${this.query}&_=`;

    // make sure to cancel the old ajax-request if user start´s a new one
    if (this.isLoading) {
      this.cancelAjax();
    } else {
      this.isLoading = true;

      this.axios
        .get(ajaxUrl, {
          cancelToken: this.axioxSource.token,
        })
        // .get('/public/assets/_dummies/autosuggest.json', {
        //   cancelToken: this.axioxSource.token,
        // }) // this line is for local dev only
        .then(({ data }) => {
          this.isLoading = false;

          this.response = data;

          this.handleTyping();

          this.hasSuggestions = this.setSuggestionState();

          if (this.hasSuggestions) {
            if (this.typeahead) {
              // set typeahead if option was set
              this.setTypeahead();
              this.checkTypeaheadMatch();
            }

            // create suggestion-list if there are suggestions (and delete the old list, if there is one)
            if (
              $(this.TEMPLATE_SELECTOR, this.autoSuggestTarget.parentElement)
            ) {
              this.destroySuggestionList();
              this.createSuggestionList();
            } else {
              this.createSuggestionList();
            }
          } else {
            // if there are no suggestions, remove typeahead and suggestion-list
            this.typeahead && this.clearTypeahead();
            this.destroySuggestionList();
          }
        })
        .catch(error => {
          // create new token after error or cancelling
          this.axioxSource = this.axios.CancelToken.source();

          if (this.axios.isCancel(error)) {
            const stopRefetch = error.message;
            this.isLoading = false;
            if (!stopRefetch) this.triggerAjax();
          } else {
            console.error(error);
            this.isLoading = false;
          }
        });
    }
  };

  createSuggestionList = () => {
    const suggestions = Object.keys(this.response.suggestions);

    insertHTML(
      this.autoSuggestTarget,
      'afterend',
      `<ul class="autocomplete-suggestions"></ul>`
    );

    suggestions.forEach((suggestion, index) => {
      const suggest = suggestion.replace(
        new RegExp(this.query, 'g'),
        `<strong>${this.query}</strong>`
      );
      const idString = `${suggestion}-${index}`;

      insertHTML(
        $(this.TEMPLATE_SELECTOR, this.autoSuggestTarget.parentElement),
        'beforeend',
        `<li class="autocomplete-suggestion"><button type="button" id="${idString.replace(
          /\s/g,
          ''
        )}" class="autocomplete-suggestion-btn" data-suggest="${suggestion}"><span>${suggest}</span></button></li>`
      );
    });

    // bind click event on buttons after creating the list
    this.bindSuggestButtons();
  };

  destroySuggestionList = () => {
    const suggestionList = $(
      this.TEMPLATE_SELECTOR,
      this.autoSuggestTarget.parentElement
    );
    suggestionList && suggestionList.parentNode.removeChild(suggestionList);
  };

  hideSuggestionList = () => {
    const suggestionList = $(
      this.TEMPLATE_SELECTOR,
      this.autoSuggestTarget.parentElement
    );
    if (suggestionList) suggestionList.style.display = 'none';
  };

  showSuggestionList = () => {
    const suggestionList = $(
      this.TEMPLATE_SELECTOR,
      this.autoSuggestTarget.parentElement
    );
    suggestionList && suggestionList.removeAttribute('style');
  };

  bindSuggestButtons = () => {
    $$(this.ITEM_BTN_SELECTOR, this.autoSuggestTarget.parentElement).forEach(
      (button, index) => {
        button.addEventListener('click', () => this.setActiveQuery(button));
        button.addEventListener('keydown', event =>
          this.addButtonKeydown(event, index)
        );
      }
    );
  };

  setActiveQuery = button => {
    const suggest = button.dataset.suggest;
    this.setQuery(suggest);
    this.setQueryToInput(suggest);
    this.handleSelect(suggest);
    this.hideSuggestionList();

    this.submitOnSuggestionClick && this.form.submit();
  };

  onBodyClick = event => {
    const { target } = event;
    const inputSelector = this.input.className
      ? `.${this.input.className.split(' ').join('.')}`
      : this.input.localName;
    const insideInput = closest(target, inputSelector, true);

    // if user clicked outside of input-field, then hide the suggestion-list, else show it
    if (insideInput) {
      this.showSuggestionList();
      this.typeahead && this.setTypeahead();
      this.checkTypeaheadMatch();
    } else {
      this.hideSuggestionList();
      this.typeahead && this.clearTypeahead();
    }
  };

  onSubmit = () => {
    this.form.addEventListener('submit', e => {
      this.preventSubmit && e.preventDefault();

      if (this.allowEmptySubmit || this.input.value !== '') {
        if (
          (this.onlySubmittingIfSuggestion && this.hasSuggestions) ||
          !this.onlySubmittingIfSuggestion ||
          (this.allowEmptySubmit && this.input.value === '')
        ) {
          this.handleSubmit();
        }
      }
    });
  };

  onInput = () => {
    let timeout = null;

    // update query and make ajax-request after every input
    this.input.addEventListener('input', () => {
      if (this.typeahead) {
        this.checkTypeaheadMatch();

        // if input is empty, clear typeahead
        if (this.input.value === '') this.clearTypeahead();
      }

      // if text length is too low, hide suggestion-list and clear typeahead
      if (this.input.value.length < this.textMinLength) {
        this.hideSuggestionList();
        this.typeahead && this.clearTypeahead();
      }

      /* Clear the timeout if it has already been set.
       * This will prevent the previous task from executing
       * if it has been less than <MILLISECONDS>
       */
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        // only trigger ajax-request if input has more than X letters
        if (this.input.value.length >= this.textMinLength) {
          this.setQuery();
          this.triggerAjax();
        }
      }, 200);
    });
  };

  initTypeahead = () => {
    // create typeahead input
    insertHTML(
      this.input,
      'beforebegin',
      `<input class="typeahead-hint" type="text" tabindex="-1" readonly />`
    );
  };

  setTypeahead = () => {
    if ($(this.TYPEAHEAD_SELECTOR) && this.response) {
      if (this.response.suggestions) {
        $(this.TYPEAHEAD_SELECTOR).value = Object.keys(
          this.response.suggestions
        )[0].toString();
      }
    }
  };

  clearTypeahead = () => {
    if ($(this.TYPEAHEAD_SELECTOR)) $(this.TYPEAHEAD_SELECTOR).value = '';
  };

  checkTypeaheadMatch = () => {
    if (this.response && this.response.suggestions) {
      // check if the actual query matches the first auto-suggest, we only show typeahead if it matches
      const regex = new RegExp('^' + this.input.value, 'g');
      const firstSuggest = Object.keys(this.response.suggestions)[0].toString();
      if (!firstSuggest.match(regex)) this.clearTypeahead();
    }
  };

  addButtonKeydown = (event, index) => {
    const key = event.key;
    const prevBtn = $$(this.ITEM_BTN_SELECTOR, this.form)[index - 1];
    const nextBtn = $$(this.ITEM_BTN_SELECTOR, this.form)[index + 1];

    // enable navigation through suggestion-list via arrow keys (Accessibility)
    if (key === 'ArrowDown') {
      event.preventDefault();
      nextBtn && nextBtn.focus();
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      if (prevBtn) {
        prevBtn.focus();
      } else {
        this.input.focus();
      }
    }
  };

  addInputKeydown = () => {
    // enable navigation through suggestion-list via arrow keys (Accessibility)
    this.input.addEventListener('keydown', event => {
      const key = event.key;
      const buttons = $$(this.ITEM_BTN_SELECTOR, this.form);

      if (key === 'ArrowDown') {
        event.preventDefault();
        if (buttons[0]) $(`#${buttons[0].id}`, this.form).focus();
      }
    });
  };

  bindEvents = () => {
    window.addEventListener('click', this.onBodyClick);
    this.onSubmit();
    this.onInput();
    this.addInputKeydown();
  };

  init = () => {
    // if no form & input is found, return
    if (!this.input || !this.form) return;

    import(/* webpackChunkName: 'axios' */ 'axios').then(_ => {
      this.axios = _.default;
      this.axioxSource = this.axios.CancelToken.source();

      this.bindEvents();
      this.typeahead && this.initTypeahead();
    });
  };
}
