import { ConsentOverlay, handleEmbed } from '@3pc/consent-overlay';

const overlays = document.querySelectorAll('[data-consent-type]');
overlays.length &&
  overlays.forEach(overlay => {
    new ConsentOverlay({
      container: overlay,
      nameSpace: 'udk',
      onActivation: (container, consentType) => {
        if (consentType === 'embed') {
          handleEmbed(container, true);
        }
      },
      onDeactivation: (container, consentType) => {
        if (consentType === 'embed') {
          handleEmbed(container, false);
        }
      },
    }).init();
  });
