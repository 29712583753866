import { $ } from 'utils/dom';
import Autosuggest from '../../04-content-components/search/helpers/Autosuggest';

const navigationForm = $('#navigation .searchform');
if (navigationForm) {
  const autoSuggestObj = new Autosuggest({
    // input which triggers solr
    input: $('input[type="search"]', navigationForm),
    // form element which contains the input
    form: navigationForm,
    // [optional] min length of text until we fire the ajax-request (0 is default)
    textMinLength: 2,
    // [optional] set true, if you want to enable typeahead
    typeahead: false,
    // [optional] prevent AutoSuggest from submitting the form (e.g. if you want to trigger ajax instead) (default is false)
    preventSubmit: false,
    // [optional] allow empty submit even if input is empty (default is false)
    allowEmptySubmit: true,
    // [optional] submit form if user clicks on a suggestion button (default is false)
    submitOnSuggestionClick: true,
  });
  autoSuggestObj.init();
}
