/** @jsx h */
import { $search, labels, endpoint, initialFilters } from './config';
import { render } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { searchStore } from './store/store';
import GlobalSearch from './modules/GlobalSearch';
import { fetchSearchData } from './helpers/helpers';
import Loading from './components/Loading';

if ($search) {
  const App = () => {
    // set default states
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [searchData, setSearchData] = useState({});
    const [pluginNamespace, setPluginNamespace] = useState('');
    const setSearchJson = searchStore(state => state.setSearchJson);

    function getSearchData(url = endpoint) {
      // get search data
      fetchSearchData(url)
        .then(({ data }) => {
          const searchData = data.content.colPos0[0].content.data;
          setSearchJson(searchData);
          setSearchData(searchData);
          setPluginNamespace(searchData.form.pluginNamespace);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Search Error:', error);
          setIsLoading(false);
          setIsError(true);
        });
    }

    function handleUnload() {
      // save url, searchData and scrollPosition when user leaves the page
      if (window.location.search) {
        const searchJson = searchStore.getState().searchJson;
        const url = window.location.href;
        const scrollPos =
          window.pageYOffset || document.documentElement.scrollTop;
        const obj = {
          url,
          searchJson,
          scrollPos,
        };
        sessionStorage.setItem(`search-${url}`, JSON.stringify(obj));
      }
    }

    useEffect(() => {
      /* if user opens the page and the searchParams are already in the sessionStorage (e.g user navigated back via browser back button)
       * show the page with the same data and scrollPosition from before
       * Else If, url has searchParams but its not in the sessionStorage, trigger ajax with the given url
       * Else If, section has initial filters, we want to add them to the intial search page query
       * Else, we fetch the search data via ajax
       */
      const url = window.location.href;
      const data = JSON.parse(sessionStorage.getItem(`search-${url}`));

      if (data && data.url === url) {
        setSearchJson(data.searchJson);
        setPluginNamespace(data.searchJson.form.pluginNamespace);
        setSearchData(data.searchJson);
        setIsLoading(false);
        setTimeout(() => window.scrollTo(0, data.scrollPos), 200);
      } else if (window.location.search) {
        getSearchData(`${endpoint}${window.location.search}`);
      } else if (initialFilters.length) {
        let searchEndpoint = endpoint;
        initialFilters.forEach((initialFilter, index) => {
          searchEndpoint += `${
            index === 0 ? '?' : '&'
          }${pluginNamespace}[filter][${index}]=${initialFilter}`;
        });
        getSearchData(encodeURI(searchEndpoint));
      } else {
        getSearchData();
      }

      /*
       * save actual url and the search json in sessionStorage when user leaves the page,
       * so if the user leave the page (in the same tab) and comes back (with browser back button),
       * we can show him the last result and scoll the user to the last position
       * */
      window.addEventListener('beforeunload', handleUnload);

      // cleanup event on unMount
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }, []);

    // show loading animation, while fetching
    if (isLoading) return <Loading />;

    if (isError)
      return <p class="search__error">{labels.search.unavailable}</p>;

    // as soon as the data has been loaded, show the search
    return (
      <GlobalSearch
        initialSearchData={searchData}
        pluginNamespace={pluginNamespace}
      />
    );
  };

  document.addEventListener('DOMContentLoaded', () => {
    render(<App />, $search);
  });
}
