import { $, $$, htmlDecode, setAttributes } from 'utils/dom';
import { uniqueId } from 'utils/helper';
import AjaxContainer from 'modules/ajax-container';
import Shower from 'modules/shower';

class EventDetail {
  // TODO: developer documentation for FE and TYPO3 (may be inside the EXT:dreipc_ems?)
  MAX_FUTURE_INDICES = 10; // @todo make this configurable via data-attribute (this data-attribute can be made configurable via TYPO3 Plugin settings)

  constructor() {
    this.indicesContainer = $('.ems-other-indices');
    this.otherIndexItems = $$('.ems-other-index');
  }

  init() {
    if (this.indicesContainer) {
      this.indicesContainer.hidden = false;
      this.updateIndex();
      // window.addEventListener('hashchange', this.updateIndex.bind(this)); currently not needed for the hsb project (e.g. in the dm project this is used for ie11-custom-properties.js)
      this.initOtherIndexItemsLinkHandler();
    }
  }

  updateIndex() {
    const indexItem = this.getIndexItemFromLocationHash();
    if (indexItem) {
      this.replaceDateText(indexItem);
      this.replaceTimeText(indexItem);
      this.toggleState(indexItem);
      this.replaceStateText(indexItem);
      this.replaceIcsUrl(indexItem);
      // this.appendDateHashToShareUrls(indexItem); currently not needed for the hsb project (e.g. in the dm project this was needed)
    }
    this.showOtherFutureIndices(indexItem);
  }

  getIndexItemFromLocationHash() {
    const dateFromURL = window.location.hash.substring(1);
    return this.otherIndexItems.filter(
      el => el.dataset.start === dateFromURL
    )[0];
  }

  replaceDateText(indexItem) {
    const eventDateTarget = $('[data-date-text-target]');
    if (eventDateTarget && indexItem.dataset.dateText !== undefined) {
      eventDateTarget.innerHTML = htmlDecode(indexItem.dataset.dateText);
    }
  }

  replaceTimeText(indexItem) {
    const eventTimeTarget = $('[data-time-text-target]');
    if (eventTimeTarget && indexItem.dataset.timeText !== undefined) {
      eventTimeTarget.outerHTML = htmlDecode(indexItem.dataset.timeText);
    }
  }

  toggleState(indexItem) {
    const isCanceled = indexItem.dataset.state === 'canceled';
    const eventStateTarget = $('[data-state-target]');
    if (eventStateTarget) {
      eventStateTarget.hidden = !isCanceled;
    }
    const eventStateToggleTarget = $('[data-state-toggle-target]');
    if (eventStateToggleTarget) {
      eventStateToggleTarget.hidden = isCanceled;
    }
  }

  replaceStateText(indexItem) {
    const eventStateTextTarget = $('[data-state-text-target]');
    if (eventStateTextTarget && indexItem.dataset.stateText !== undefined) {
      eventStateTextTarget.outerHTML = htmlDecode(indexItem.dataset.stateText);
    }
  }

  replaceIcsUrl(indexItem) {
    const eventIcsTarget = $('[data-ics-url-target]');
    if (eventIcsTarget) {
      if (indexItem.dataset.icsUrl !== undefined) {
        eventIcsTarget.href = indexItem.dataset.icsUrl;
        eventIcsTarget.hidden = false;
      } else {
        eventIcsTarget.hidden = true;
      }
    }
  }

  appendDateHashToShareUrls(indexItem) {
    if (indexItem.dataset.start === undefined) {
      return;
    }
    // @todo This selector was used for the dm project but might be to specific. Consider using a more generic selector.
    $$('.share-wrapper .social-media-list a').forEach(link => {
      if (link.dataset.baseUri === undefined) {
        link.dataset.baseUri = link.href;
      }
      link.href =
        link.dataset.baseUri +
        encodeURIComponent('#' + indexItem.dataset.start);
    });
  }

  showOtherFutureIndices(currentIndexItem) {
    let count = 0;
    this.otherIndexItems.forEach(indexItem => {
      const indexDate = new Date(indexItem.dataset.start);
      if (
        count < this.MAX_FUTURE_INDICES &&
        currentIndexItem !== indexItem &&
        indexDate.getTime() > Date.now()
      ) {
        indexItem.hidden = false;
        ++count;
      } else {
        indexItem.hidden = true;
      }
    });
  }

  initOtherIndexItemsLinkHandler() {
    $$('.ems-other-index a[href^="#"]').forEach(link => {
      link.addEventListener('click', () => {
        window.scrollTo(0, 0);
      });
    });
  }
}

new EventDetail().init();

const events = $$('.events[data-ajax-onload]');
events.length &&
  new AjaxContainer({
    targets: events,
  }).init();

// Accordion of Aside Container
const accordionItems = $$('.events__accordion-container');

if (accordionItems.length) {
  accordionItems.forEach(item => {
    const randomID = uniqueId();
    const target = $('.events__accordion-content', item);
    const handler = $('.events__accordion-btn', item);

    setAttributes(target, {
      id: `acc-btn-${randomID}`,
      'aria-controls': `acc-tab-${randomID}`,
    });

    setAttributes(handler, {
      id: `acc-tab-${randomID}`,
      'aria-labelledby': `acc-btn-${randomID}`,
    });

    new Shower({
      target,
      handler,
      easing: 'easeInOutQuart',
      duration: 400,
    }).init();
  });
}
