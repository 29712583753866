import create from 'zustand';

const searchStore = create(set => ({
  searchJson: {},
  setSearchJson: data => set(() => ({ searchJson: data })),
}));

const useAccordionStore = create(set => ({
  activeAccordions: [],
  addAccordion: title => set(state => state.activeAccordions.push(title)),
  removeAccordion: title =>
    set(state => ({
      activeAccordions: state.activeAccordions.filter(
        accTitle => accTitle !== title
      ),
    })),
}));

export { searchStore, useAccordionStore };
