/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   File:   datepickerDay.js
 */
export default class DatePickerDay {
  constructor(
    domNode,
    datepicker,
    index,
    row,
    column,
    disablePastDates,
    disablePastMonths
  ) {
    this.index = index;
    this.row = row;
    this.column = column;

    this.day = new Date();

    this.domNode = domNode;
    this.datepicker = datepicker;
    this.disablePastDates = disablePastDates;
    this.disablePastMonths = disablePastMonths;

    this.keyCode = Object.freeze({
      TAB: 9,
      ENTER: 13,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
    });
  }

  init = () => {
    this.domNode.setAttribute('tabindex', '-1');
    this.domNode.addEventListener('mousedown', this.handleMouseDown.bind(this));
    this.domNode.addEventListener('keydown', this.handleKeyDown.bind(this));

    this.domNode.innerHTML = '-1';
  };

  isDisabled = () => this.domNode.classList.contains('disabled');

  updateDay = (disable, day) => {
    if (disable) {
      this.domNode.classList.add('disabled');
    } else {
      this.domNode.classList.remove('disabled');
    }

    this.day = new Date(day);

    this.domNode.innerHTML = this.day.getDate();
    this.domNode.setAttribute('tabindex', '-1');
    this.domNode.removeAttribute('aria-selected');

    let d = this.day.getDate().toString();
    if (this.day.getDate() < 10) d = '0' + d;

    let m = this.day.getMonth() + 1;
    if (m < 10) m = '0' + m;

    this.domNode.setAttribute(
      'data-date',
      this.day.getFullYear() + '-' + m + '-' + d
    );

    // disable dates, that are in the pass dates
    if (this.disablePastDates) {
      const date = new Date(this.domNode.dataset.date);
      const today = new Date();

      // if button date is before and is not the actual date: disable the button
      if (
        !(date > today) &&
        !(
          date.getFullYear() === today.getFullYear() &&
          date.getMonth() === today.getMonth() &&
          date.getDate() === today.getDate()
        )
      ) {
        this.domNode.setAttribute('disabled', 'true');
      } else {
        this.domNode.removeAttribute('disabled');
      }
    }

    // disable dates, that are in the pass months
    if (this.disablePastMonths) {
      const date = new Date(this.domNode.dataset.date);
      const today = new Date();

      // reset dates on month change
      this.domNode.removeAttribute('disabled');

      // disable every date in the past from the actual month
      if (
        date.getFullYear() < today.getFullYear() ||
        (date.getMonth() < today.getMonth() &&
          date.getFullYear() <= today.getFullYear())
      ) {
        this.domNode.setAttribute('disabled', 'true');
      }
    }
  };

  handleKeyDown = event => {
    let flag = false;

    switch (event.keyCode) {
      case this.keyCode.ESC:
        this.datepicker.hide();
        break;

      case this.keyCode.ENTER:
      case this.keyCode.SPACE:
        this.datepicker.setTextboxDate(this.day, event);
        this.datepicker.hide();
        flag = true;
        break;

      case this.keyCode.RIGHT:
        this.datepicker.moveFocusToNextDay();
        flag = true;
        break;

      case this.keyCode.LEFT:
        this.datepicker.moveFocusToPreviousDay();
        flag = true;
        break;

      case this.keyCode.DOWN:
        this.datepicker.moveFocusToNextWeek();
        flag = true;
        break;

      case this.keyCode.UP:
        this.datepicker.moveFocusToPreviousWeek();
        flag = true;
        break;

      case this.keyCode.PAGEUP:
        if (event.shiftKey) {
          this.datepicker.moveToPreviousYear();
        } else {
          this.datepicker.moveToPreviousMonth();
        }
        flag = true;
        break;

      case this.keyCode.PAGEDOWN:
        if (event.shiftKey) {
          this.datepicker.moveToNextYear();
        } else {
          this.datepicker.moveToNextMonth();
        }
        flag = true;
        break;

      case this.keyCode.HOME:
        this.datepicker.moveFocusToFirstDayOfWeek();
        flag = true;
        break;

      case this.keyCode.END:
        this.datepicker.moveFocusToLastDayOfWeek();
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  handleMouseDown = event => {
    if (this.isDisabled()) {
      this.datepicker.moveFocusToDay(this.date);
    } else {
      this.datepicker.setTextboxDate(this.day, event);
      this.datepicker.hide();
    }

    event.stopPropagation();
    event.preventDefault();
  };
}
