/** @jsx h */
import { Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import Datepicker from '../../../02-base/datepicker/datepicker';
import { labels } from '../config';
import { getFormattedDate } from '../helpers/helpers';

const DatePicker = ({ triggerAjaxSubmit, pluginNamespace, facet }) => {
  const refButton = useRef(null);
  const refInput = useRef(null);
  const refDialog = useRef(null);
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState('');

  function handleClickOutside(event) {
    if (
      refDialog.current.contains(event.target) ||
      refButton.current.contains(event.target)
    ) {
      return;
    }

    if (refDialog.current.classList.contains('is-open')) {
      setOpenDatepicker(false);
    }
  }

  useEffect(() => {
    if (refDialog && refInput) {
      const datepickerInput = refInput.current;
      const datePickerClass = new Datepicker({
        inputNode: refInput.current,
        dialogNode: refDialog.current,
        disablePastDates: false,
        disablePastMonths: false,
        onDaySet: date => {
          const dateValue = getFormattedDate(date, `${facet.name}:___FROM___-`);

          datepickerInput.value = dateValue; // update input value

          setDatePickerValue(dateValue);
        },
      });
      datePickerClass.init();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    if (datePickerValue !== '') {
      triggerAjaxSubmit();
    }
  }, [datePickerValue]);

  return (
    <Fragment>
      <div class="grid-controls-datepicker">
        <button
          type="button"
          class={`btn btn-default prevent-udk ${
            openDatepicker ? 'expanded' : ''
          }`}
          aria-controls="gcd-0"
          ref={refButton}
          onClick={() => setOpenDatepicker(!openDatepicker)}
        >
          {labels.faceting.selectDate} <span class="caret" />
        </button>

        <div
          class="datepicker grid-datepicker"
          style={`display: ${openDatepicker ? 'block' : 'none'}`}
        >
          <div class="datepicker__date sr-only">
            <label for="id-textbox-1">{labels.faceting.selectDate}</label>
            <input
              ref={refInput}
              type="text"
              placeholder="dd.mm.yyyy"
              id="id-textbox-1"
              class="datepicker__input"
              data-value={`${facet.name}:___FROM___-`}
              name={`${pluginNamespace}[filter][]`}
              aria-autocomplete="none"
              readonly
            />
          </div>
          <div
            class={`datepicker__dialog grid-datepicker ${
              openDatepicker ? 'is-open' : ''
            }`}
            aria-labelledby="id-dialog-label"
            ref={refDialog}
          >
            <div class="datepicker__header datepicker-header">
              <button
                class="datepicker__button datepicker__prev-year btn btn-default btn-previous"
                type="button"
                aria-label="previous year"
                hidden
              >
                <span class="genericon genericon-previous " />
                <span class="sr-only">{labels.search.previousYear}</span>
              </button>
              <button
                class="datepicker__button datepicker__prev-month btn btn-default btn-previous"
                type="button"
                aria-label="previous month"
              >
                <span class="genericon genericon-previous" />
                <span class="sr-only">{labels.search.previousMonth}</span>
              </button>
              <div
                id="id-dialog-label"
                class="datepicker__month-year datepicker-current-month"
                aria-live="polite"
              />
              <button
                class="datepicker__button datepicker__next-month btn btn-default btn-next"
                type="button"
                aria-label="next month"
              >
                <span class="genericon genericon-next" />
                <span class="sr-only">{labels.search.nextMonth}</span>
              </button>
              <button
                class="datepicker__button datepicker__next-year btn btn-default btn-next"
                type="button"
                aria-label="next year"
                hidden
              >
                <span class="genericon genericon-next" />
                <span class="sr-only">{labels.search.nextYear}</span>
              </button>
            </div>
            <table
              id="myDatepickerGrid"
              class="datepicker__dates"
              role="grid"
              aria-labelledby="id-dialog-label"
            >
              <thead>
                <tr>
                  <th scope="col" abbr="Sunday">
                    So
                  </th>
                  <th scope="col" abbr="Monday">
                    Mo
                  </th>
                  <th scope="col" abbr="Tuesday">
                    Di
                  </th>
                  <th scope="col" abbr="Wednesday">
                    Mi
                  </th>
                  <th scope="col" abbr="Thursday">
                    Do
                  </th>
                  <th scope="col" abbr="Friday">
                    Fr
                  </th>
                  <th scope="col" abbr="Saturday">
                    Sa
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DatePicker;
